module.exports = {
  siteTitle: 'Advertising agency 🌱 Art Open', // Site title.
  siteTitleAlt: 'Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and shops, e-commerce, graphic design, VR and 3D, calendars.', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512-512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://artopen.co', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/artopen/.
  siteDescription: 'Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and shops, e-commerce, graphic design, VR and 3D, calendars.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '830740164459215', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-M3WQ2LH', // GTM tracking ID.
  disqusShortname: 'art-open', // Disqus shortname.
  userName: '🌱 Art Open',
  userTwitter: '@AReklamy',
  userLocation: 'Wrocław, Poland',
  userDescription: '🌱 Pro Ecological advertising agency for planning, designing and implementing marketing solutions. 🖥️  💻 📱',
  copyright: 'All rights reserved', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#23C495', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
